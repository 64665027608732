import React from "react"
import styled from "styled-components"
import { Box } from "@material-ui/core"

import AppLayout from "@components/layout/App"
import CAREER_LEVELS_CONTENT from "@content/career-levels.json"

import UnstyledLink from "@components/elements/UnstyledLink"

import { getCareerLevelUrl } from "@helpers/url"

const GRADE_BOX_COLUMN_COUNT = 5

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 0 8px;
`

const TitleContainer = styled.div`
  padding: 2rem 0;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 1rem 0;
  }
`

const SubHeader = styled.p`
  color: #666;
  font-size: 16px;
`

const GridContainer = styled.div`
  display: grid;

  grid-template-columns: 300px repeat(${GRADE_BOX_COLUMN_COUNT}, 90px);
  grid-auto-rows: 60px;
  column-gap: 8px;
  row-gap: 24px;
`

const GridFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  background: ${props => props.bgColor || "transparent"};
  color: white;
  grid-column-end: span ${props => props.gridColumnSpan || 1};

  height: 100%;

  .grade-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }

  .job-name {
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
  }
`

const LevelGridFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  padding-right: 16px;
`

const EmptyGradeBox = styled.div`
  background: #e6e7e8;
`

const GridFlexBox = styled.div`
  position: relative; /* for hover effect */

  grid-column-end: span ${props => props.gridColumnSpan || 1};
`

export default function CareerBandsPage() {
  const renderGradeBoxes = careerLevel => {
    const grades = careerLevel.grades

    // TODO: render empty box
    const boxElems = Array.from({ length: GRADE_BOX_COLUMN_COUNT }, (v, i) => (
      <EmptyGradeBox key={`empty-grade-box-${i}`} />
    ))

    grades.forEach((grade, index) => {
      const boxIndex = grade.gridColumnStart - 1
      const gradeBox = (
        <GridFlexBox
          gridColumnSpan={grade.gridColumnSpan}
          key={`grade-box-${index}`}
        >
          <GridFlex
            bgColor={careerLevel.bgColor}
            gridColumnSpan={grade.gridColumnSpan}
          >
            <Box>
              <div className="grade-name">{grade.name}</div>
              <div className="job-name">{grade.title}</div>
            </Box>
          </GridFlex>
          <Box
            component={UnstyledLink}
            to={getCareerLevelUrl(careerLevel.slug)}
            position="absolute"
            width={1}
            height={1}
            style={{ top: 0, left: 0, right: 0, bottom: 0 }}
          />
        </GridFlexBox>
      )

      boxElems[boxIndex] = gradeBox

      // set null for skipping rendering
      for (let i = 1; i < grade.gridColumnSpan; i++) {
        boxElems[boxIndex + i] = null
      }
    })

    return boxElems.map(box => box)
  }

  return (
    <AppLayout>
      <PageContainer>
        <TitleContainer>
          <h1>AD Government Grades</h1>
          <SubHeader>
            Click on any AD gov grades within the career bands to view criteria
          </SubHeader>
        </TitleContainer>

        <Box pb={2}>
          <GridContainer>
            {CAREER_LEVELS_CONTENT.map((cl, clIndex) => {
              return (
                <React.Fragment key={`career-levels-content-${clIndex}`}>
                  <LevelGridFlex>{cl.name}</LevelGridFlex>
                  {renderGradeBoxes(cl)}
                </React.Fragment>
              )
            })}
          </GridContainer>
        </Box>
      </PageContainer>
    </AppLayout>
  )
}
